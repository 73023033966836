import React from "react";
import withAULayout from "../../../components/au.desktop/layout";
import loadable from "@loadable/component";
import PageLoader from "../../../components/shared/page-loader";
// import PageNotFoundRevamp from "../../../components/au.desktop/page-not-found-revamp/component";
const PageNotFoundAu = loadable(() => import("../../../components/shared/page-not-found-au"), {
    fallback: <PageLoader />
});
// const PageNotFoundRevamp = loadable(() => import("../../../components/au.desktop/page-not-found-revamp"), {
//     fallback: <PageLoader />
// });

const NotFound = () => {
    return <PageNotFoundAu />;
};

export default withAULayout(NotFound);
